<template>
    <section class="goddess">
        <img src="@/assets/active/poster_en.png" alt="">
    </section>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
    }
};
</script>
<style scoped lang="less">
.goddess {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    img {
        width: 100%;
        display: block;
    }
}
</style>